.w300[data-v-47dd3bd4] {
  width: 300px !important;
}
.close_icon[data-v-47dd3bd4] {
  position: absolute;
  top: 14px;
  right: 27px;
  cursor: pointer;
  z-index: 99;
}
.dialog_footer[data-v-47dd3bd4] {
  text-align: right;
}