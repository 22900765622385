.header_top[data-v-c32451b8] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record[data-v-c32451b8] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record[data-v-c32451b8]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.margin0[data-v-c32451b8] {
  margin: 0 !important;
}
.w650[data-v-c32451b8] {
  width: 650px !important;
}